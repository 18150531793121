.tsparticle {
    overflow: hidden !important;
    width: 100% !important;
    background-color: transparent;
    position: absolute !important;
    top: 0;
    height: 100% !important;
    opacity: 0.7;
}

@media(max-width:768px) {
    .tsparticle {
        display: none;
    }
}