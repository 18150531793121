@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    box-sizing: border-box;
}
html {
    overflow-x: hidden;

}

html::-webkit-scrollbar {
    display: none;
}

.hov-dur:hover {
    transition: 0.4s !important;
}

@layer base {

    .font-josefin-sans {
        font-family: 'Josefin Sans', sans-serif;
    }

    .font-font {
        font-family: 'Courier New', Courier, monospace;
        font-family: Verdana, Geneva, Tahoma, sans-serif;

    }
}
.b{
    /* border: 1px solid red; */
}

.t{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.line-6{
    overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 6;
}

.b{
    border: 1px solid red;
}
.font-sans{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}